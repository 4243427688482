import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Layout from '../../../components/layout/Layout';
import CandidatesService from '../services/candidates.service';
import { Candidate } from '../ts/candidates.interface';
import ImageHolder from '../../../components/user/ImageHolder';
import LoadingOverlay from '../../../components/layout/LoadingOverlay';
import CandidateTemplateBox from './CandidateTemplateBox';
import MagicLinkModal from './MagicLinkModal';
import notifications from '../../../utils/notifications';
import BackArrowLink from '../../../components/common/BackArrowLink';
import DocumentService from '../services/document.service';
import { UNEXPECTED_ERROR_MESSAGE } from '../../../utils/constants';
import { COMPANY_MANAGER_ID } from '../../authentication/ts/auth_interfaces';
import PrimaryButton from '../../../components/common/PrimaryButton';
import useUser from '../../authentication/hooks/useUser';


interface Props {
    className?: string;
}

function CandidatesShow({ className = '' }: Props) {
    const { candidateId } = useParams();
    const {
        user, isUserLoading, error, userRole,
    } = useUser();
    const { accessId } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [magicLink, setMagicLink] = useState<string>('');
    const [candidate, setCandidate] = useState<Candidate>();
    const [isMagicLinkLoading, setIsMagicLinkLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const getIconClass = (): string => {
        if (!candidate) {
            return 'available';
        }
        return CandidatesService.getCandidateAvailabilityClass(candidate.usermeta.availability.id);
    };
    const statusIconClass = useMemo(() => getIconClass(), [candidate]);


    const getCandidate = () => {
        if (!candidateId) {
            return;
        }
        setIsLoading(true);
        CandidatesService.getCandidateById(candidateId).then((candidateResponse) => {
            if (!candidateResponse) {
                navigate('/');
                return;
            }
            setCandidate(candidateResponse);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        getCandidate();
    }, [candidateId]);

    const pdfBtnAction = (templateId: string) => {
        setIsMagicLinkLoading(true);
        DocumentService.downloadPdf({ functionId: candidate?.id ?? '', companyId: templateId })
            .then((res) => {
                setIsMagicLinkLoading(false);
                if (res.token) {
                    window.open(res.url, '_blank');
                } else {
                    notifications.error(UNEXPECTED_ERROR_MESSAGE);
                }
            });
    };

    const docBtnAction = (templateId: string) => {
        setIsMagicLinkLoading(true);
        DocumentService.downloadDocx({ functionId: candidate?.id ?? '', companyId: templateId })
            .then((res) => {
                setIsMagicLinkLoading(false);
                if (res.token) {
                    window.open(res.url, '_blank');
                } else {
                    notifications.error(UNEXPECTED_ERROR_MESSAGE);
                }
            });
    };

    const magicLinkAction = (templateId: string) => {
        setIsMagicLinkLoading(true);
        DocumentService.getMagicLink({ functionId: candidate?.id ?? '', companyId: templateId }).then((res) => {
            setIsMagicLinkLoading(false);
            if (!res.url) {
                notifications.warning('Failed to generate magic link for requested candidate. Please try again later.');
                Sentry.captureException(new Error('Failed to generate magic link for requested candidate. Please try again later.'));
                return;
            }
            setMagicLink(res.url);
        });
    };

    const onMagicLinkModalClose = () => {
        setMagicLink('');
    };

    if (isLoading) {
        return <LoadingOverlay />;
    }

    const onClickEdit = () => {
        // @ts-ignore
        navigate(`/resume/${candidate.usermeta.id}/edit`);
    };
    if (!candidate) {
        // TODO return 404 page
        return null;
    }
    return (
        <Layout>

            <div className="content line mobilePadding">
                <div
                    className="align-items-center align-items-sm-center d-flex d-sm-flex flex-wrap justify-content-between justify-content-sm-between mb-4"
                >
                    <div className="align-items-center d-flex flex-row">
                        <BackArrowLink to="/candidates" />
                        <div className="align-items-center img-holder-wrapper">
                            <ImageHolder
                                image={candidate.usermeta.image}
                                name={candidate.usermeta.full_name}
                                className="me-4 ms-2 ovalImg"
                            />
                        </div>
                        <div>
                            <p className="mb-1 profileName">{candidate.usermeta.full_name}</p>
                            <p className="mb-0 profileNumber">{candidate.user}</p>
                        </div>
                    </div>
                    <div className="candidatesDetailProfession mt-3 mt-sm-0">
                        <div className="d-flex align-items-center gap-2">
                            {(userRole === COMPANY_MANAGER_ID || user?.is_superuser) && (
                                <div>
                                    <PrimaryButton
                                        text="Edit Profile"
                                        onClick={() => onClickEdit()}
                                        className="cancelBtn"
                                    />
                                </div>
                            )}
                            <span>{candidate.name}</span>
                        </div>
                    </div>

                </div>
                <div
                    className="align-items-sm-center align-items-start d-flex d-sm-flex flex-column flex-sm-row justify-content-sm-between justify-content-start mb-sm-2 mt-4 mt-sm-4 "
                >

                    <div>
                        <h3 className="darkGrey overview">Overview</h3>
                        {(candidate.templates.length !== 0 && !isLoading) && (
                            <p className="defaultGreyText mb-0">Templates you can apply</p>
                        )}
                    </div>
                </div>

                <div>
                    {candidate.templates.map((template) => (
                        <CandidateTemplateBox
                            key={template.id}
                            template={template}
                            candidate={candidate}
                            isDocLoading={isMagicLinkLoading}
                            isPdfLoading={isMagicLinkLoading}
                            isMagicLinkLoading={isMagicLinkLoading}
                            magicLinkAction={magicLinkAction}
                            docBtnAction={docBtnAction}
                            pdfBtnAction={pdfBtnAction}
                        />
                    ))}
                    {(candidate.templates.length === 0 && !isLoading) && (
                        <div className="d-flex">
                            <p className="profileNumber" style={{ paddingBottom: '0px', marginBottom: '0px' }}>
                                No templates available.
                                To assign user to a company:
                                {' '}
                                <button
                                    type="button"
                                    role="link"
                                    tabIndex={0}
                                    onClick={() => navigate(`/access/${candidate.usermeta.id}/edit`)}
                                    className="btn-link template-click-here"
                                >
                                    click here
                                </button>
                            </p>
                        </div>
                    )}
                </div>
                {(candidate.templates.length === 0 && !isLoading) ? (
                    <hr className="mb-4" />
                ) : (
                    <hr className="mb-4 mt-4" />
                )}
                <div>
                    <div className="defaultGreyText detailsGrid">
                        <i className="align-items-center d-flex fa-envelope fa-lg fas justify-content-start" />
                        <span>E-mail</span>
                        <span className="bold darkGrey">
                            <a
                                href={`mailto:${candidate.usermeta.email}`}
                                target="_blank"
                                rel="noreferrer"
                                className="no-href-style"
                            >
                                {candidate.usermeta.email}
                            </a>
                        </span>
                    </div>
                    <div className="defaultGreyText detailsGrid">
                        <i className="align-items-center d-flex fa-lg fa-phone-alt fas justify-content-start" />
                        <span>Phone</span>

                        <span className="bold darkGrey">
                            {candidate.usermeta.phone_number ? (
                                <a
                                    className="no-href-style"
                                    href={`tel:${candidate.usermeta.phone_number}`}
                                >
                                    {candidate.usermeta.phone_number}
                                </a>
                            ) : (
                                '-'
                            )}
                        </span>
                    </div>
                    <div className="defaultGreyText detailsGrid">
                        <i className="align-items-center d-flex fa-lg fa-server fas justify-content-start" />
                        <span className="align-items-center d-flex">Status</span>

                        <span className={`availableBtn ${statusIconClass} me-lg-3 me-sm-3`}>
                            {candidate.usermeta.availability.name}
                        </span>
                    </div>
                    <div className="defaultGreyText detailsGrid">
                        <i className="align-items-center d-flex fa-house-damage fa-lg fas justify-content-start" />
                        <span>Region</span>
                        <span className="bold darkGrey">{candidate.usermeta.city ?? '-'}</span>
                    </div>
                </div>
            </div>

            <MagicLinkModal link={magicLink} onClose={onMagicLinkModalClose} />


        </Layout>
    );
}

export default CandidatesShow;
