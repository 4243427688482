/* eslint-disable prefer-destructuring */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { User } from '@sentry/react';
import Layout from '../../../components/layout/Layout';
import ImageUpload from './form/ImageUpload';
import { ResumeCreateForm, ResumeFormErrors, UserEducation } from '../ts/resume.interface';
import LoadingOverlay from '../../../components/layout/LoadingOverlay';
import ResumeInput from './form/ResumeInput';
import {
    ERROR, GENDER_FEMALE, GENDER_MALE, GENDER_UNDEFINED, SUCCESS, 
} from '../../../dto/constants.dto';
import { ResumeService } from '../services/resume.service';
import PrimaryButton from '../../../components/common/PrimaryButton';
import notifications from '../../../utils/notifications';
import {
    GENERAL_SUCCESS_MESSAGE,
    GENERAL_VALIDATION_FAIL_MESSAGE,
    UNEXPECTED_ERROR_MESSAGE,
} from '../../../utils/constants';
import EducationRepeater from './EducationRepeater/EducationRepeater';
import DatePickerInput, { DatePickerSelectAction } from '../../../components/common/DatePickerInput';
import useUser from '../../authentication/hooks/useUser';
import ResumeInputRepeater, { ReperaterInput } from './form/ResumeInputRepeater';
import { PostRequestProps } from '../../../lib/axios/request';
import FunctionService from '../../functions/services/function.service';
import BackArrowLink from '../../../components/common/BackArrowLink';
import UserService from '../../authentication/services/UserService';


interface Props {
    className?: string;
}

function ResumeForm({ className = '' }: Props) {
    const { user: authenticatedUser, error, isUserLoading } = useUser();
    const { accessId } = useParams();
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [image, setImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [educations, setEducations] = useState<UserEducation[]>([]);

    const [functionSkills, setFunctionSkills] = useState<ReperaterInput[]>([]);
    const [functionTools, setFunctionTools] = useState<ReperaterInput[]>([]);
    const [formInputs, setFormInputs] = useState<ResumeCreateForm>();

    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState<ResumeFormErrors>({
        image: '',
        nationality: '',
        gender: '',
        phone_number: '',
        city: '',
        birth_date: '',
        full_name: '',
        tools: '',
        skills: '',
        field_of_study: {},
        institution_name: {},
        city_education: {},
        country: {},
    });

    const resetFormErrors = () => {
        setFormErrors({
            image: '',
            nationality: '',
            gender: '',
            phone_number: '',
            city: '',
            birth_date: '',
            full_name: '',
            tools: '',
            skills: '',
            city_education: {},
            field_of_study: {},
            institution_name: {},
            country: {},
        });
    };

    const setUserInitialData = (userLocal: User) => {
        setUser(userLocal);
        setImagePreview(userLocal.image);
        setFormInputs({
            full_name: userLocal.full_name,
            nationality: userLocal.nationality,
            gender: userLocal.gender,
            phone_number: userLocal.phone_number,
            city: userLocal.city,
            birth_date: userLocal.birth_date,
            availability: userLocal.availability.id,
            skills: null,
            tools: null,
            educations: [],
        });
        if (userLocal.tools) {
            setFunctionTools(FunctionService.resolveRepeaterInputsFromBackend(userLocal.tools));
        }
        if (userLocal.skills) {
            setFunctionSkills(FunctionService.resolveRepeaterInputsFromBackend(userLocal.skills));
        }
    };

    useEffect(() => {
        if (!authenticatedUser) {
            return;
        }
        console.log('accessId: ', accessId);
        if (accessId) {
            UserService.getUserByAccessId({ accessId }).then((res) => {
                setUserInitialData(res);
            });
        } else {
            setUserInitialData(authenticatedUser);
        }
    }, [accessId, authenticatedUser]);


    const onChangeBirthDate = (date: DatePickerSelectAction) => {
        // @ts-ignore
        setFormInputs({ ...formInputs, birth_date: date.date_YYYY_MM_DD });
    };

    const onChangeEvt = (evt: ChangeEvent<HTMLInputElement>) => {
        let value = null;
        if (evt.target.value) {
            value = evt.target.value;
        }
        // @ts-ignore
        setFormInputs({ ...formInputs, [evt.target.name]: value });
    };

    const onFileSelect = (file: File | null) => {
        setImage(file);
    };

    const handleError = ({ response }: PostRequestProps) => {
        const errors = response.data;
        try {
            const newFormErrors = {
                image: '',
                nationality: '',
                gender: '',
                phone_number: '',
                city: '',
                birth_date: '',
                full_name: '',
                tools: '',
                skills: '',
                city_education: {} as { [key: string]: any },
                field_of_study: {} as { [key: string]: any },
                institution_name: {} as { [key: string]: any },
                country: {} as { [key: string]: any },
            };
            Object.keys(errors).forEach((key) => {
                if (key === 'educations') {
                    Object.keys(errors.educations).forEach((educationKey) => {
                        if (errors.educations[educationKey].institution_name) {
                            newFormErrors.institution_name[educationKey] = errors.educations[educationKey].institution_name[0];
                        }
                        if (errors.educations[educationKey].field_of_study) {
                            newFormErrors.field_of_study[educationKey] = errors.educations[educationKey].field_of_study[0];
                        }
                        if (errors.educations[educationKey].city_education) {
                            newFormErrors.city_education[educationKey] = errors.educations[educationKey].city_education[0];
                        }
                        if (errors.educations[educationKey].country) {
                            newFormErrors.country[educationKey] = errors.educations[educationKey].country[0];
                        }
                    });
                } else {
                    // @ts-ignore
                    // eslint-disable-next-line prefer-destructuring
                    newFormErrors[key] = errors[key][0];
                }
            });
            setFormErrors(newFormErrors);
        } catch (e) {
            Sentry.captureException(e);
            notifications.warning(GENERAL_VALIDATION_FAIL_MESSAGE);
        }
    };
    const handleSuccess = () => {
        notifications.success(GENERAL_SUCCESS_MESSAGE);
        setTimeout(() => {
            if (typeof accessId !== 'undefined') {
                navigate(`/access/${accessId}/edit`);
            } else {
                window.location.href = '/';
            }
        }, 700);
    };

    const setEducationsAction = (evt: UserEducation[]) => {
        setEducations(evt);
    };

    const onSave = async () => {
        if (!formInputs) {
            console.warn('No user inputs, should not happen.');
            notifications.error(UNEXPECTED_ERROR_MESSAGE);
            return;
        }
        resetFormErrors();

        setIsLoading(true);
        formInputs.educations = educations;
        formInputs.tools = FunctionService.prepareRepeaterValuesForBackend(functionTools);
        formInputs.skills = FunctionService.prepareRepeaterValuesForBackend(functionSkills);
        console.log('form inputs: ', formInputs);
        const { response, status } = await ResumeService.updateResume(formInputs, accessId);
        if (image) {
            // has to be separate because of encoding:
            const { response: resImage, status: statusImage } = await ResumeService.updateUserImage({
                image,
                accessId,
            });
            if (statusImage === ERROR) {
                setFormErrors({ ...formErrors, image: (resImage.data.image ? resImage.data.image[0] : '') });
                setIsLoading(false);
                return;
            }
        }
        setIsLoading(false);
        if (status === ERROR) {
            handleError({ response, status });
        }
        if (status === SUCCESS) {
            handleSuccess();
        }
    };

    if (!user || isUserLoading) {
        return (<LoadingOverlay />);
    }

    return (
        <Layout>
            <div className="content line mobilePadding">
                <div className="align-items-center d-flex justify-content-between">

                    <BackArrowLink to="/" />

                    <PrimaryButton
                        text="Save"
                        onClick={() => onSave()}
                        className="saveBtn"
                        isLoading={isLoading}
                    />
                </div>
                <div className="align-items-center d-flex justify-content-center mt-5">
                    <ImageUpload
                        onChange={(file) => onFileSelect(file)}
                        previewImage={imagePreview}
                        errorText={formErrors?.image ?? ''}
                    />
                </div>
                <div style={{ maxWidth: '720px' }} className="me-auto ms-auto pb-3 pt-5">
                    <p className="defaultGreyText">Personal Details</p>
                    <div className="formGrid mb-5 mt-5">
                        <ResumeInput
                            label="Full name"
                            required
                            value={formInputs?.full_name ?? ''}
                            name="full_name"
                            onChange={(e) => onChangeEvt(e)}
                            placeholder="John Doe"
                            errorText={formErrors?.full_name ?? ''}
                        />
                        <ResumeInput
                            label="Nationality"
                            required
                            value={formInputs?.nationality ?? ''}
                            name="nationality"
                            onChange={(e) => onChangeEvt(e)}
                            placeholder="TU, Eindhoven"
                            errorText={formErrors?.nationality ?? ''}
                        />

                        <div className="d-flex flex-column formGridItem">
                            <label htmlFor="gender" className="editLabel required mb-2">Gender</label>
                            <select
                                className="editInput "
                                placeholder="Gender"
                                name="gender"
                                // @ts-ignore
                                onChange={(e) => onChangeEvt(e)}
                                value={formInputs?.gender ?? ''}
                            >
                                <option value="">Select your gender</option>
                                <option value={GENDER_MALE}>Male</option>
                                <option value={GENDER_FEMALE}>Female</option>
                                <option value={GENDER_UNDEFINED}>Other</option>
                            </select>
                            {formErrors.gender && (
                                <small className="error-text">{formErrors.gender}</small>
                            )}
                        </div>

                        <ResumeInput
                            required
                            label="Phone Number"
                            value={formInputs?.phone_number ?? ''}
                            name="phone_number"
                            onChange={(e) => onChangeEvt(e)}
                            placeholder="+38512345678"
                            errorText={formErrors?.phone_number ?? ''}
                        />
                        <ResumeInput
                            required
                            label="City"
                            value={formInputs?.city ?? ''}
                            name="city"
                            onChange={(e) => onChangeEvt(e)}
                            placeholder="Eindhoven"
                            errorText={formErrors?.city ?? ''}
                        />

                        <div className="d-flex flex-column">
                            <DatePickerInput
                                startDateObj={(user?.birth_date ? new Date(user?.birth_date) : null)}
                                className="datePickerInput"
                                onChange={(date) => onChangeBirthDate(date)}
                                label="Birth Date"
                                required
                                errorText={formErrors?.birth_date ?? ''}
                            />
                        </div>
                    </div>
                </div>
                <hr className="line" style={{ height: 'auto' }} />


                <div className="me-auto ms-auto " style={{ maxWidth: '720px' }}>
                    <div className="formGridItem mt-md-4">
                        <div className="d-flex flex-column">
                            <ResumeInputRepeater
                                identifier="skills"
                                valuesArr={functionSkills}
                                label="Skills"
                                onChangeAction={(e) => setFunctionSkills(e)}
                                errorText={formErrors?.skills}
                            />
                        </div>
                    </div>
                    <div className="formGridItem mt-md-4">
                        <div className="d-flex flex-column">
                            <ResumeInputRepeater
                                identifier="skills"
                                valuesArr={functionTools}
                                label="Tools"
                                onChangeAction={(e) => setFunctionTools(e)}
                                errorText={formErrors?.tools}
                            />
                        </div>
                    </div>
                </div>
                <hr className="line" style={{ height: 'auto' }} />

                {user && (
                    <div className="me-auto ms-auto mt-3 pt-5" style={{ maxWidth: '720px' }}>

                        <EducationRepeater
                            formErrors={formErrors}
                            userEducations={user.educations}
                            onChangeEducation={(evt) => setEducationsAction(evt)}
                        />
                    </div>
                )}
            </div>

        </Layout>
    );
}

export default ResumeForm;
